import { IconButton, Tooltip } from "@material-ui/core";
import { Print } from "@mui/icons-material";
import {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useContext,
    useMemo,
    useRef,
} from "react";
import ReactToPrint from "react-to-print";
import UserContext from "../UserContext";

type Props = {
    componentRef: MutableRefObject<any>;
    printMode?: boolean;
    setPrintMode: Dispatch<SetStateAction<boolean>>;
    color?: any;
};

const PrintTable = ({
    componentRef,
    printMode = false,
    setPrintMode,
    color,
}: Props) => {
    const triggerRef = useRef<any>(null);
    const { mode, setMode } = useContext(UserContext);

    const currTheme = useMemo(() => mode, []);

    const handlePrint = () => {
        setPrintMode(true);
        setMode("light");
        setTimeout(() => {
            triggerRef.current?.click();
            setTimeout(() => {
                setMode(currTheme);
                setPrintMode(false);
            }, 1000);
        }, 1000);
    };

    return (
        <>
            <ReactToPrint
                trigger={() => <section hidden ref={triggerRef} />}
                content={() => componentRef.current}
            />

            <Tooltip title="Print" style={{ color: color ? color : "white" }}>
                <IconButton onClick={handlePrint}>
                    <Print />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default PrintTable;
