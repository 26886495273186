import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createBrowserHistory } from "history";
// import User from "./components/user/user";
import { useEffect, useMemo, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import DarkTheme from "./components/DarkTheme";
import ErrorBoundary from "./components/ErrorBoundary";
import LightTheme from "./components/LightTheme";
import UniversalBackdropLoading from "./components/UniversalBackdropLoading";
import UniversalFloatingFeedback from "./components/UniversalFloatingFeedback";
import UserContext from "./components/UserContext";
import { getAllSettings, getUserPermissions } from "./services/me.service";

function App() {
    const [feedback, setFeedback] = useState({
        show: false,
        severity: "",
        title: "",
        message: "",
    });
    const [user, setUser] = useState(null);
    const [offline, setOffline] = useState(false);
    const [permissions, setPermissions] = useState(null);
    const [mode, setMode] = useState(localStorage.getItem("theme") || "dark");
    const [showNavbar, setShowNavbar] = useState(true);
    const [settings, setSettings] = useState({
        school_name: "Zama School",
        logo: `icon.png`,
    });
    const [backdropLoading, setBackdropLoading] = useState(false);

    const theme = useMemo(
        () => (mode === "dark" ? DarkTheme : LightTheme),
        [mode]
    );

    useEffect(() => {
        localStorage.setItem("theme", mode);
    }, [mode]);

    const history = createBrowserHistory();

    const [store, setStore] = useState({});

    useEffect(() => {
        const htmlElement = document.querySelector("html");
        const rootElement = document.querySelector("#root");

        htmlElement.style.backgroundColor = theme.palette.background.default;
        rootElement.style.backgroundColor = theme.palette.background.default;
    }, [theme]);

    useEffect(() => {
        if (user) getUserPermissions().then((perms) => setPermissions(perms));
    }, [user]);

    useEffect(() => {
        if (permissions)
            getAllSettings().then((allSettings) => {
                setSettings({ ...settings, ...allSettings });
            });
    }, [permissions]);

    // useEffect(() => {
    //     if (navigator.serviceWorker) {
    //         // caches
    //         //     .delete("paths")
    //         //     .then(() => {
    //         navigator.serviceWorker
    //             .register("sw-cache-site.js")
    //             .then((reg) => {
    //                 console.log("Serive worker registered");
    //             })
    //             .catch((err) => {
    //                 console.log(`Service worker registration failed: ${err}`);
    //             });
    //         // })
    //         // .catch(() => {});
    //     }
    // }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <UserContext.Provider
                    value={{
                        user,
                        setUser,
                        permissions,
                        setPermissions,
                        settings,
                        mode,
                        setMode,
                        feedback,
                        setFeedback,
                        setShow: (value) =>
                            setFeedback({ ...feedback, show: value }),
                        backdropLoading: backdropLoading,
                        setBackdropLoading,
                        store,
                        setStore,
                        offline,
                        setOffline,
                        showNavbar,
                        setShowNavbar,
                    }}
                >
                    <CssBaseline />

                    <ErrorBoundary>
                        <Routes />
                        <UniversalFloatingFeedback />
                        <UniversalBackdropLoading text={backdropLoading.text} />
                    </ErrorBoundary>
                </UserContext.Provider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
