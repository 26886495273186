import { CircularProgress } from "@material-ui/core";

const Loading = () => {
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <CircularProgress style={{ color: "orange" }} />
        </div>
    );
};

export default Loading;
