import { Avatar, Box, Typography, makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction, useContext } from "react";
import { EditableTypography } from "../../EditableTypography";
import UserContext from "../../UserContext";
import { ReportHeaderProps } from "./HeaderPreferences";

type Props = {
    orgInfo: ReportHeaderProps;
    setOrgInfo?: Dispatch<SetStateAction<ReportHeaderProps>>;
};

export function ReportHeader({ orgInfo, setOrgInfo }: Props) {
    const classes = useStyles();
    const { store, settings, user } = useContext(UserContext);

    //
    return (
        <Box textAlign="center" className={classes.wrapper}>
            <Box
                display="flex"
                alignItems="center"
                px={4}
                flexDirection={
                    orgInfo.logoPos.value === "right" ? "row-reverse" : "row"
                }
            >
                <Box>
                    {store.logo && (
                        <Avatar
                            src={URL.createObjectURL(store.logo)}
                            style={{ width: 64, height: 64 }}
                            // imgProps={{ width: 96, height: 96 }}
                        />
                    )}
                </Box>

                <Box flex={1}>
                    {orgInfo.name.selected && (
                        <Typography variant="h4">
                            {settings["organization-name"]
                                ? settings["organization-name"]
                                : user &&
                                  user.baseUser.type === "user" &&
                                  user.classroom !== null
                                ? user.classroom.class
                                : "Zama Classroom"}
                        </Typography>
                    )}

                    {orgInfo.address.selected && (
                        // <Typography variant="h6">{orgInfo.address}</Typography>
                        <EditableTypography
                            typoProps={{ variant: "h6" }}
                            initialValue={
                                orgInfo.address.value || "Your Address"
                            }
                            onEdit={(value) => {
                                setOrgInfo &&
                                    setOrgInfo((curr) => ({
                                        ...curr,
                                        address: { ...curr.address, value },
                                    }));
                            }}
                        />
                    )}

                    {orgInfo.contactNo.selected && (
                        // <Typography variant="h6">{orgInfo.contactNo}</Typography>
                        <EditableTypography
                            textFieldProps={{ type: "number" }}
                            typoProps={{ variant: "h6" }}
                            initialValue={
                                orgInfo.contactNo.value || "Contact No"
                            }
                            onEdit={(value) => {
                                setOrgInfo &&
                                    setOrgInfo((curr) => ({
                                        ...curr,
                                        contactNo: { ...curr.contactNo, value },
                                    }));
                            }}
                        />
                    )}

                    {orgInfo.email.selected && (
                        <Typography variant="h6">
                            {user?.baseUser.email}
                        </Typography>
                    )}
                </Box>

                <Box width={64}></Box>
            </Box>
        </Box>
    );
}

//
const useStyles = makeStyles((theme) => ({
    wrapper: {
        // minHeight: "5cm",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(4, 0),
    },
}));

{
    /* <Grid
    container
    px={2}
    py={1}
    minHeight="5cm"
    borderRadius={1}
    justifyContent="space-between"
    alignItems="center"
>
    <Grid item>
        {leftImage && (
            <img
                src={leftImage}
                style={{
                    width: "75px",
                    height: "75px",
                }}
                alt=""
            />
        )}
    </Grid>
    <Grid item textAlign="center">
        {selected.campusName ? (
            <Typography variant="h4">{campus.name}</Typography>
        ) : null}

        {selected.campusAddress ? (
            <Typography variant="h6">{campus.address} </Typography>
        ) : null}

        {selected.clientPhone ? (
            <Typography variant="h6">
                {client.client.phone}
            </Typography>
        ) : null}

        {selected.clientEmail ? (
            <Typography variant="h6">
                {client.client.email}
            </Typography>
        ) : null}

        {additionalRow ? (
            <Typography variant="h6">{additionalRow}</Typography>
        ) : null}
    </Grid>
    <Grid item>
        {rightImage && (
            <Avatar
                src={rightImage}
                sx={{ width: "75px", height: "75px" }}
            >
                <BrokenImageRounded
                    sx={{ width: "50%", height: "50%" }}
                />
            </Avatar>
        )}
    </Grid>
</Grid> */
}
