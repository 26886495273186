import { createContext, Dispatch, SetStateAction } from "react";
import { Feedback } from "../types";

interface UserContext {
    user: any;
    setUser: (newUser: any) => void;
    permissions: string[];
    setPermissions: (newPermissions: string[]) => void;
    settings: any;
    mode: string;
    setMode: (newMode: string) => void;
    feedback: Feedback;
    setFeedback: Dispatch<SetStateAction<any>>;
    setShow: (value: boolean) => void;
    userVariant: string;
    backdropLoading: boolean;
    setBackdropLoading: Dispatch<SetStateAction<boolean>>;
    store: any;
    setStore: Dispatch<SetStateAction<any>>;
    offline: boolean;
    setOffline: Dispatch<SetStateAction<boolean>>;
    showNavbar: boolean;
    setShowNavbar: Dispatch<SetStateAction<boolean>>;
}

const context = createContext<UserContext>({} as UserContext);

export default context;
