import {
    Collapse,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import Loadable from "react-loadable";
import { useHistory } from "react-router-dom";
import Loading from "../Loading";
import UserContext from "../UserContext";
import { default as Subjects, default as UserSubjects } from "../lms/subjects";
import SimpleSidebarComponent from "./SimpleSidebarComponent";

const AsyncTopics = Loadable({
    loader: () => import("../lms/topics"),
    loading: Loading,
});
const AsyncChapters = Loadable({
    loader: () => import("../lms/chapters"),
    loading: Loading,
});

const AsyncCourse = Loadable({
    loader: () => import("../../components/lms-user/AddCourse"),
    loading: Loading,
});

const AsyncArchive = Loadable({
    loader: () => import("../Archive/Archive"),
    loading: Loading,
});

const AsyncAssessment = Loadable({
    loader: () => import("../Assessment/Assessment"),
    loading: Loading,
});

const AsyncCertificate = Loadable({
    loader: () => import("../Certificates/Certificates"),
    loading: Loading,
});

const AsyncCertificateHistory = Loadable({
    loader: () => import("../Certificates/History/CertificateHistory"),
    loading: Loading,
});

const AsyncEnrollments = Loadable({
    loader: () => import("../Enrollment/Enrollments"),
    loading: Loading,
});

const AsyncEvents = Loadable({
    loader: () => import("../Events/Events"),
    loading: Loading,
});

const AsyncLogs = Loadable({
    loader: () => import("../Logs/Logs"),
    loading: Loading,
});

const AsyncForms = Loadable({
    loader: () => import("../Form/Forms"),
    loading: Loading,
});

const AsyncMultiPermissionAuthorize = Loadable({
    loader: () => import("../MultiPermissionAuthorize"),
    loading: Loading,
});

const AsyncDataImport = Loadable({
    loader: () => import("../DataImport/DataImport"),
    loading: Loading,
});

const AsyncSession = Loadable({
    loader: () => import("../LiveSession/Sessions"),
    loading: Loading,
});

const AsyncCollections = Loadable({
    loader: () => import("../Payment/Collection/Collections"),
    loading: Loading,
});

const AsyncHeads = Loadable({
    loader: () => import("../Payment/Head/Heads"),
    loading: Loading,
});

const AsyncPayments = Loadable({
    loader: () => import("../Payment/Payments/Payments"),
    loading: Loading,
});

const AsyncDashboard = Loadable({
    loader: () => import("../PersonalDashboard/Dashboard"),
    loading: Loading,
});

const AsyncCreateRole = Loadable({
    loader: () => import("../Rolesystem/CreateRole"),
    loading: Loading,
});

const AsyncShowRoles = Loadable({
    loader: () => import("../Rolesystem/ShowRoles"),
    loading: Loading,
});

const AsyncTokens = Loadable({
    loader: () => import("../Token/Tokens"),
    loading: Loading,
});

const AsyncStudentSubjects = Loadable({
    loader: () => import("../lms-student/Subjects"),
    loading: Loading,
});

const AsyncEmployeeStudent = Loadable({
    loader: () => import("../lms/EmployeeStudent"),
    loading: Loading,
});

const AsyncStudents = Loadable({
    loader: () => import("../lms/Students"),
    loading: Loading,
});

const AsyncUsers = Loadable({
    loader: () => import("../lms/Users"),
    loading: Loading,
});

const AsyncClassrooms = Loadable({
    loader: () => import("../lms/classrooms"),
    loading: Loading,
});

const AsyncEmployees = Loadable({
    loader: () => import("../lms/employees"),
    loading: Loading,
});

const AsyncStudentProctorExam = Loadable({
    loader: () => import("../Exam/StudentProctorExam"),
    loading: Loading,
});

const AsyncProctorExams = Loadable({
    loader: () => import("../ProctorExam/ProctorExams"),
    loading: Loading,
});

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const facultyModules = {
    Dashboard: [
        {
            name: "Dashboard",
            component: <AsyncDashboard />,
            permissions: [],
            href: "/employee/dashboard",
        },
    ],

    Students: [
        {
            name: "Students",
            component: <AsyncEmployeeStudent />,
            permissions: ["READ STUDENTS"],
            href: "/employee/student",
        },
    ],

    "Zama Classroom": [
        {
            name: "My Courses",
            component: <UserSubjects addSubject variant="employee" />,
            permissions: ["READ SUBJECTS"],
            href: "/employee/courses",
        },

        {
            name: "Chapters",
            component: <AsyncChapters variant="employee" />,
            permissions: ["READ CHAPTERS"],
            href: "/employee/chapters",
        },

        {
            name: "Topics",
            component: <AsyncTopics variant="employee" />,
            permissions: ["READ TOPICS"],
            href: "/employee/topics",
        },

        {
            name: "Add Courses",
            component: <AsyncCourse variant="employee" />,
            permissions: ["CREATE CHAPTER", "READ CHAPTERS"],
            href: "/employee/courses/add",
        },
    ],

    Tokens: [
        {
            name: "Tokens",
            component: <AsyncTokens />,
            permissions: ["CREATE TOKEN", "READ TOKENS"],
            href: "/employee/tokens",
        },
    ],

    Archive: [
        {
            name: "Archive",
            component: <AsyncArchive />,
            permissions: ["READ SUBJECT_ARCHIVE", "READ CHAPTER_ARCHIVE"],
            href: "/employee/archive",
        },
    ],

    Assessment: [
        {
            name: "Assessment",
            component: <AsyncAssessment variant="employee" />,
            permissions: ["READ ASSESSMENT"],
            href: "/employee/assessment",
        },
    ],

    Enrollments: [
        {
            name: "Enrollment",
            component: <AsyncEnrollments />,
            permissions: [],
            href: "/employee/enrollments",
        },
    ],

    "Live Sessions": [
        {
            name: "Live sessions",
            component: <AsyncSession />,
            href: "/live/sessions",
            permissions: [
                "CREATE COACHING_SESSION",
                "READ COACHING_SESSION",
                "EDIT COACHING_SESSION",
                "DELETE COACHING_SESSION",
            ],
        },
    ],

    Payment: [
        {
            name: "Payments",
            component: <AsyncPayments />,
            permissions: ["READ PAYMENT"],
            href: "/employee/payment",
        },

        {
            name: "Collections",
            component: <AsyncCollections />,
            permissions: [
                "CREATE COLLECTION",
                "READ COLLECTION",
                "VERIFY COLLECTION",
            ],
            href: "/employee/payment/collection",
        },
    ],

    "Proctor Exam": [
        {
            name: "Proctor Exam",
            component: <AsyncProctorExams />,
            permissions: ["READ EXAM"],
            href: "/employee/proctor-exams",
        },
    ],
};

export const studentModules = {
    Classroom: [
        {
            name: "Courses",
            component: <AsyncStudentSubjects />,
            permissions: ["READ SUBJECTS"],
            href: "/student/courses",
        },
        {
            name: "Chapters",
            component: <AsyncChapters />,
            permissions: ["READ CHAPTERS"],
            href: "/student/chapters",
        },
        {
            name: "Topics",
            component: <AsyncTopics />,
            permissions: ["READ TOPICS"],
            href: "/student/topics",
        },
    ],
    Assessment: [
        {
            name: "Assessment",
            component: <AsyncAssessment variant="student" />,
            permissions: ["READ ASSESSMENT"],
            href: "/student/assessment",
        },
    ],
    Enrollments: [
        {
            name: "Enrollment",
            component: <AsyncEnrollments />,
            permissions: ["READ ENROLLMENT"],
            href: "/student/enrollments",
        },
    ],
    "Live Sessions": [
        {
            name: "Live sessions",
            component: <AsyncSession />,
            href: "/live/sessions",
            permissions: ["READ COACHING_SESSION"],
        },
    ],
    Payment: [
        {
            name: "Payments",
            component: <AsyncPayments />,
            permissions: ["READ PAYMENT"],
            href: "/student/payment",
        },
        {
            name: "Collections",
            component: <AsyncCollections />,
            permissions: [
                "CREATE COLLECTION",
                "READ COLLECTION",
                "VERIFY COLLECTION",
            ],
            href: "/student/payment/collection",
        },
    ],

    "Assigned Certificates": [
        {
            name: "Assigned Certificates",
            component: <AsyncCertificateHistory />,
            permissions: ["READ CERTIFICATE_HISTORY"],
            href: "/student/assigned/certificate",
        },
    ],

    Events: [
        {
            name: "Events",
            component: <AsyncEvents />,
            href: "/student/events",
            permissions: ["READ FORM"],
        },
    ],

    "Proctor Exam": [
        {
            name: "Proctor Exam",
            component: <AsyncStudentProctorExam />,
            href: "/student/exam",
            permissions: ["READ EXAM"],
        },
    ],
};

export const userModules = {
    Dashboard: [
        {
            name: "Dashboard",
            component: <AsyncDashboard />,
            permissions: ["READ DASHBOARD", "READ SIMPLE_DASHBOARD"],
            href: "/user",
        },
    ],

    Rolesystem: [
        {
            name: "Create Role",
            component: <AsyncCreateRole />,
            permissions: ["CREATE ROLE"],
            href: "/create-role",
        },

        {
            name: "Roles",
            component: <AsyncShowRoles />,
            permissions: ["READ ROLE"],
            href: "/roles",
        },
    ],

    Users: [
        {
            name: "Users",
            component: <AsyncUsers />,
            permissions: ["READ USERS"],
            href: "/show-users",
        },
    ],

    "Zama Classroom": [
        {
            name: "Classes",
            component: <AsyncClassrooms />,
            permissions: ["READ CLASSROOMS"],
            href: "/classrooms",
        },
    ],

    Employees: [
        {
            name: "Employees",
            component: <AsyncEmployees />,
            permissions: ["READ EMPLOYEES"],
            href: "/employees",
        },
    ],

    "Courses, Chapters & Topics": [
        {
            name: "Courses",
            component: <Subjects addSubject variant="user" />,
            permissions: ["CREATE SUBJECT", "READ SUBJECTS"],
            href: "/user/courses",
        },

        {
            name: "Chapters",
            component: <AsyncChapters variant="user" />,
            permissions: ["CREATE CHAPTER", "READ CHAPTERS"],
            href: "/user/chapters",
        },

        {
            name: "Topics",
            component: <AsyncTopics variant="user" />,
            permissions: ["CREATE CHAPTER", "READ CHAPTERS"],
            href: "/user/topics",
        },

        {
            name: "Add Courses",
            component: <AsyncCourse variant="user" />,
            permissions: ["CREATE CHAPTER", "READ CHAPTERS"],
            href: "/user/courses/add",
        },
    ],

    Students: [
        {
            name: "Students",
            component: <AsyncStudents />,
            permissions: ["READ STUDENTS"],
            href: "/students",
        },
    ],

    Import: [
        {
            name: "Students",
            component: <AsyncDataImport />,
            permissions: ["IMPORT STUDENTS"],
            href: "/import/students",
        },
    ],

    Tokens: [
        {
            name: "Tokens",
            component: <AsyncTokens />,
            permissions: ["CREATE TOKEN", "READ TOKENS"],
            href: "/user/tokens",
        },
    ],

    Archive: [
        {
            name: "Archive",
            component: <AsyncArchive />,
            permissions: [
                "READ CLASSROOM_ARCHIVE",
                "READ SUBJECT_ARCHIVE",
                "READ USER_ARCHIVE",
                "READ EMPLOYEE_ARCHIVE",
                "READ STUDENT_ARCHIVE",
                "READ CLASSWORK_ARCHIVE",
                "READ FORM_ARCHIVE",
                "READ EVENT_ARCHIVE",
            ],
            href: "/user/archive",
        },
    ],

    // Assessment: [
    //     {
    //         name: "Assessment",
    //         component: <AsyncAssessment variant="user" />,
    //         permissions: ["READ CHAPTERS"],
    //         href: "/user/assessment",
    //     },
    // ],

    Enrollments: [
        {
            name: "Enrollment",
            component: <AsyncEnrollments />,
            permissions: ["READ ENROLLMENT"],
            href: "/user/enrollments",
        },
    ],

    Logs: [
        {
            name: "Logs",
            component: <AsyncLogs />,
            permissions: ["READ LOGS"],
            href: "/user/logs",
        },
    ],
    Payment: [
        {
            name: "Heads",
            component: <AsyncHeads />,
            permissions: [
                "CREATE PAYMENT_HEAD",
                "READ PAYMENT_HEAD",
                "EDIT PAYMENT_HEAD",
                "DELETE PAYMENT_HEAD",
            ],
            href: "/user/payment/head",
        },
        {
            name: "Payments",
            component: <AsyncPayments />,
            permissions: [
                "CREATE PAYMENT",
                "READ PAYMENT",
                "EDIT PAYMENT",
                "DELETE PAYMENT",
            ],
            href: "/user/payment",
        },
        {
            name: "Collections",
            component: <AsyncCollections />,
            permissions: [
                "CREATE COLLECTION",
                "READ COLLECTION",
                "DELETE COLLECTION",
                "VERIFY COLLECTION",
            ],
            href: "/user/payment/collection",
        },
    ],

    Certificates: [
        {
            name: "Certificates",
            component: <AsyncCertificate />,
            permissions: ["READ CERTIFICATE"],
            href: "/user/certificate",
        },

        {
            name: "Assign Certificate",
            component: <AsyncCertificateHistory />,
            permissions: ["READ CERTIFICATE_HISTORY"],
            href: "/user/certificate/assign",
        },
    ],

    "Zama Forms": [
        {
            name: "Zama Form",
            component: <AsyncForms />,
            href: "/user/forms",
            permissions: ["READ FORM"],
        },
    ],

    Events: [
        {
            name: "Events",
            component: <AsyncEvents />,
            href: "/user/events",
            permissions: ["READ EVENT"],
        },
    ],

    "Proctor Exam": [
        {
            name: "Proctor Exam",
            component: <AsyncProctorExams />,
            permissions: ["READ EXAM"],
            href: "/user/exam",
        },
    ],
};

export default function Sidebar({ variant, setComponent }) {
    const classes = useStyles();
    const history = useHistory();
    const { user, permissions } = useContext(UserContext);
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState({});
    const modules = useMemo(() => {
        switch (variant) {
            case "employee":
                return facultyModules;
            case "student":
                return studentModules;
            default:
                return userModules;
        }
    }, [variant]);

    const handleSelect = (subModule) => {
        setSelected(subModule.name);
        setComponent(subModule.component);
    };

    useEffect(() => {
        Object.keys(modules).map((mod) => setOpen({ ...open, mod: false }));
    }, []);

    return (
        user && (
            <List>
                {/* <SimpleSidebarComponent
                name="Dashboard"
                targetComponent={
                    <div>Future dashboard</div>
                }
                setComponent={setComponent}
            /> */}
                {variant !== "user" && (
                    <AsyncMultiPermissionAuthorize ops={["READ CLASSWORK"]}>
                        <SimpleSidebarComponent name="Classwork" href="/" />
                    </AsyncMultiPermissionAuthorize>
                )}

                {permissions &&
                    user &&
                    Object.entries(modules)
                        // .sort((a, b) => b[1].length - a[1].length)
                        .map(([module, subModules], idx) =>
                            subModules.length === 1 ? (
                                <AsyncMultiPermissionAuthorize
                                    ops={subModules[0].permissions || []}
                                    strategy="some"
                                    key={`${module}-${idx}`}
                                >
                                    <SimpleSidebarComponent
                                        name={module}
                                        href={subModules[0].href}
                                    />
                                </AsyncMultiPermissionAuthorize>
                            ) : (
                                <Fragment key={idx}>
                                    {subModules
                                        .map((sub) => sub.permissions || [])
                                        .reduce((ops, op) => [...ops, ...op])
                                        .some((op) =>
                                            permissions.includes(op)
                                        ) ? (
                                        <ListItem
                                            button
                                            onClick={() =>
                                                setOpen({
                                                    ...open,
                                                    [module]: !open[[module]],
                                                })
                                            }
                                        >
                                            <ListItemText primary={module} />
                                            {open[[module]] ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ListItem>
                                    ) : null}

                                    <Collapse
                                        in={open[module]}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List disablePadding dense>
                                            {subModules.map(
                                                (subModule, idx2) => (
                                                    <AsyncMultiPermissionAuthorize
                                                        ops={
                                                            subModule.permissions ||
                                                            []
                                                        }
                                                        key={idx2}
                                                        strategy="some"
                                                    >
                                                        <ListItem
                                                            selected={
                                                                selected ===
                                                                subModule.name
                                                            }
                                                            button
                                                            className={
                                                                classes.nested
                                                            }
                                                            onClick={() => {
                                                                handleSelect(
                                                                    subModule
                                                                );
                                                                history.push(
                                                                    subModule.href
                                                                );
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={
                                                                    subModule.name
                                                                }
                                                            />
                                                        </ListItem>
                                                    </AsyncMultiPermissionAuthorize>
                                                )
                                            )}
                                        </List>
                                    </Collapse>
                                </Fragment>
                            )
                        )}
            </List>
        )
    );
}
