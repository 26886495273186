import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useContext, useEffect, useState } from "react";
import {
    createSubject,
    createSubjectCopy,
} from "../../services/subject.service";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import { FormControl } from "@mui/material";
import UserContext from "../UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    form: {
        width: "100%",
        "& > *": {
            minWidth: "20%",
            margin: theme.spacing(1),
        },
    },
}));

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

export const CompletionStrategy = {
    Autocomplete: "autocomplete",
    Manual: "manual",
    Student: "student",
};

export const ContentAccess = {
    "Full-Access": "fullaccess",
    "Assignments-Only": "assignment",
    "Quizzes-Only": "quiz",
    "Material-Only": "material",
    "No-Access": "none",
};

const FeeAlert = ({ open, setOpen, message, action }) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>
                <Alert variant="outlined" severity="warning">
                    Copying Alert
                </Alert>
            </DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    variant="outlined"
                    color="primary"
                >
                    Close
                </Button>
                {action}
            </DialogActions>
        </Dialog>
    );
};

export default function AddSubjectEmployee({ setSubjectsAdded }) {
    const { user } = useContext(UserContext);
    const classes = useStyles();
    const [classrooms, setClassrooms] = useState([]);
    const [newSubject, setNewSubject] = useState({
        subject: "",
        classroom: "",
        _public: "",
        owner: "",
        completionStrategy: CompletionStrategy["Manual"],
        duration: 7,
        contentAccessAfterComplete: "fullaccess",
        copyContent: false,
        course: "",
    });

    const [owner, setOwner] = useState("");
    const [open, setOpen] = useState(false);

    const [feedback, setFeedback] = useState(initialFeedback);

    const handleChange = (e) => {
        setNewSubject({ ...newSubject, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        console.log("it hits");
        e.preventDefault();
        setFeedback({ ...initialFeedback, loading: true });

        if (newSubject.copyContent == false) {
            const [data, err] = await createSubject({
                ...newSubject,
                classroom: user.classroom.id,
            });

            if (data) {
                setSubjectsAdded((n) => n + 1);
                setFeedback({
                    loading: false,
                    message: data.message,
                    hidden: false,
                    severity: "success",
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    hidden: false,
                    severity: "error",
                });
            }
        } else {
            console.log(newSubject);
            const [data, err] = await createSubjectCopy({
                ...newSubject,
                copyContent: undefined,
                owner: owner,
                classroom: user.classroom.id,
            });

            if (data) {
                setSubjectsAdded((n) => n + 1);
                setFeedback({
                    loading: false,
                    message: data.message,
                    hidden: false,
                    severity: "success",
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    hidden: false,
                    severity: "error",
                });
            }
        }
    };

    const handleClick = (ev, newSubject) => {
        if (newSubject.copyContent == true) {
            setOpen(true);
        } else {
            handleSubmit(ev);
        }
    };

    useEffect(() => {
        setOwner(user.baseUser.employee.id);
    }, []);

    return (
        <Accordion className={classes.root}>
            <AccordionSummary>
                <Typography variant="h5">Create Course</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    container
                    component="form"
                    spacing={1}
                    onSubmit={handleSubmit}
                >
                    <Grid
                        item
                        xs={12}
                        component="section"
                        hidden={feedback.hidden}
                        style={{ paddingBottom: "1rem" }}
                    >
                        <Alert
                            severity={feedback.severity}
                            style={{ width: "100%" }}
                        >
                            {feedback.message}
                        </Alert>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            name="subject"
                            label="Course name"
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        {/* <TextField
                            fullWidth
                            select
                            name="public"
                            label="Visibility"
                            variant="outlined"
                            value={newSubject.public}
                            onChange={handleChange}
                        >
                            <MenuItem value={false}>Private</MenuItem>
                            <MenuItem value={true}>Public</MenuItem>
                        </TextField> */}

                        <Autocomplete
                            defaultOptions={[
                                { id: false, name: "Private" },
                                { id: true, name: "Public" },
                            ]}
                            labelKey="name"
                            label="Visibility"
                            setOutput={(p) => {
                                setNewSubject({
                                    ...newSubject,
                                    _public: p.id,
                                });
                            }}
                            textFieldProps={{ variant: "outlined" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            select
                            fullWidth
                            required
                            name="completionStrategy"
                            value={newSubject.completionStrategy}
                            onChange={handleChange}
                            label="Completion Strategy"
                            variant="outlined"
                        >
                            {Object.entries(CompletionStrategy).map((entry) => (
                                <MenuItem key={entry[1]} value={entry[1]}>
                                    {entry[0]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {newSubject.completionStrategy === "autocomplete" && (
                        <Grid item xs={12} md={2} lg={3}>
                            <TextField
                                required
                                fullWidth
                                type="number"
                                label="Duration in days"
                                name="duration"
                                variant="outlined"
                                value={newSubject.duration}
                                onChange={handleChange}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={3} lg={3}>
                        <TextField
                            select
                            fullWidth
                            variant="outlined"
                            required
                            name="contentAccessAfterComplete"
                            value={newSubject.contentAccessAfterComplete}
                            onChange={handleChange}
                            label="Access to content after course completion"
                        >
                            {Object.entries(ContentAccess).map((entry) => (
                                <MenuItem key={entry[1]} value={entry[1]}>
                                    {entry[0]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl>
                            <FormControlLabel
                                label="Copy Content"
                                name="copyContent"
                                control={
                                    <Checkbox
                                        value={newSubject.copyContent}
                                        onChange={(ev, checked) =>
                                            setNewSubject({
                                                ...newSubject,
                                                copyContent: checked,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>

                    {newSubject.copyContent && (
                        <Grid item xs={12} md={4} lg={3}>
                            <Autocomplete
                                api="/class/subject/all-employee-subjects"
                                apiParams={{
                                    employee: user && user.baseUser.employee.id,
                                }}
                                setOutput={(sub) =>
                                    setNewSubject({
                                        ...newSubject,
                                        course: sub?.id || undefined,
                                    })
                                }
                                label="Subject"
                                labelKey="subject"
                                textFieldProps={{ variant: "outlined" }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={3} lg={3}>
                        <Button
                            style={{ height: "100%" }}
                            fullWidth
                            color="primary"
                            variant="outlined"
                            onClick={(ev) => handleClick(ev, newSubject)}
                        >
                            Create Course
                        </Button>
                    </Grid>
                </Grid>

                <FeeAlert
                    open={open}
                    setOpen={setOpen}
                    message={
                        <Typography variant="body1">
                            Are you sure you want to copy content from a
                            previous course? This action will duplicate all
                            materials and resources from the selected course
                            into the new one. Please note that any changes made
                            in the new course will not affect the original. This
                            action cannot be undone. Are you certain you
                            wish to proceed?
                        </Typography>
                    }
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(ev) => handleSubmit(ev)}
                        >
                            OK
                        </Button>
                    }
                />
            </AccordionDetails>
        </Accordion>
    );
}
