import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { ChangeEvent, FormEvent, useContext, useState } from "react";
import {
    createSubject,
    createSubjectCopy,
} from "../../services/subject.service";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import { FormControl } from "@mui/material";
import UserContext from "../UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    form: {
        width: "100%",
        "& > *": {
            minWidth: "20%",
            margin: theme.spacing(1),
        },
    },
}));

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

const FeeAlert = ({ open, setOpen, message, action }: any) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>
                <Alert variant="outlined" severity="warning">
                    Copying Alert
                </Alert>
            </DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setOpen(false)}
                    variant="outlined"
                    color="primary"
                >
                    Close
                </Button>
                {action}
            </DialogActions>
        </Dialog>
    );
};

export function AddSubject({ setSubjectsAdded }: any) {
    const classes = useStyles();
    const { user } = useContext(UserContext);

    const [newSubject, setNewSubject] = useState({
        subject: "",
        classroom: "",
        _public: "",
        owner: "",
        copyContent: false,
        course: "",
    });
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState(initialFeedback);
    const handleChange = (e: ChangeEvent<any>) => {
        setNewSubject({ ...newSubject, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e: FormEvent<any>) => {
        e.preventDefault();
        setFeedback({ ...initialFeedback, loading: true });

        if (newSubject.copyContent == false) {
            const [data, err] = await createSubject({
                ...newSubject,
                classroom:
                    user && user.classroom
                        ? user.classroom.id
                        : newSubject.classroom,
            });

            if (data) {
                setSubjectsAdded((n: number) => n + 1);
                setFeedback({
                    loading: false,
                    message: data.message,
                    hidden: false,
                    severity: "success",
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    hidden: false,
                    severity: "error",
                });
            }
        } else {
            console.log(newSubject.classroom);
            const [data, err] = await createSubjectCopy({
                ...newSubject,
                copyContent: undefined,
                classroom:
                    user && user.classroom
                        ? user.classroom.id
                        : newSubject.classroom,
            });

            if (data) {
                setSubjectsAdded((n: number) => n + 1);
                setFeedback({
                    loading: false,
                    message: data.message,
                    hidden: false,
                    severity: "success",
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    hidden: false,
                    severity: "error",
                });
            }
        }
    };

    const handleClick = (ev: any, newSubject: any) => {
        if (newSubject.copyContent == true) {
            setOpen(true);
        } else {
            handleSubmit(ev);
        }
    };

    return (
        <Accordion className={classes.root}>
            <AccordionSummary>
                <Typography variant="h5">Create Course</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
            >
                <Grid
                    container
                    spacing={1}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Grid
                        item
                        xs={12}
                        component="section"
                        hidden={feedback.hidden}
                        style={{ paddingBottom: "1rem" }}
                    >
                        <Alert
                            severity={feedback.severity as any}
                            style={{ width: "100%" }}
                        >
                            {feedback.message}
                        </Alert>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="subject"
                            label="Course name"
                            onChange={handleChange}
                        />
                    </Grid>

                    {user && !user.classroom && (
                        <Grid item xs={12} md={4} lg={3}>
                            <Autocomplete
                                api="/class/all-classes-admin"
                                setOutput={(cls: any) =>
                                    setNewSubject({
                                        ...newSubject,
                                        classroom: cls?.id || "",
                                    })
                                }
                                label="Classroom"
                                labelKey="class"
                                textFieldProps={{ variant: "outlined" }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4} lg={3}>
                        <Autocomplete
                            defaultOptions={[
                                { id: false, name: "Private" },
                                { id: true, name: "Public" },
                            ]}
                            labelKey="name"
                            label="Visibility"
                            setOutput={(p) => {
                                setNewSubject({
                                    ...newSubject,
                                    _public: p?.id,
                                });
                            }}
                            textFieldProps={{ variant: "outlined" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <Autocomplete
                            api="/employee/all-employees"
                            setOutput={(own: any) =>
                                setNewSubject({
                                    ...newSubject,
                                    owner: own?.id || "",
                                })
                            }
                            label="Employee"
                            labelKey="name"
                            textFieldProps={{ variant: "outlined" }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl>
                            <FormControlLabel
                                label="Copy Content"
                                name="feeApplicable"
                                control={
                                    <Checkbox
                                        value={newSubject.copyContent}
                                        onChange={(ev, checked) =>
                                            setNewSubject({
                                                ...newSubject,
                                                copyContent: checked,
                                            })
                                        }
                                        color="primary"
                                    />
                                }
                            />
                        </FormControl>
                    </Grid>

                    {newSubject.copyContent && (
                        <Grid item xs={12} md={4} lg={3}>
                            <Autocomplete
                                api="/class/subject/all-subjects"
                                setOutput={(sub: any) =>
                                    setNewSubject({
                                        ...newSubject,
                                        course: sub?.id || undefined,
                                    })
                                }
                                label="Subject"
                                labelKey="subject"
                                textFieldProps={{ variant: "outlined" }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4} lg={3}>
                        <Button
                            style={{ height: "100%" }}
                            fullWidth
                            color="primary"
                            variant="outlined"
                            onClick={(ev) => handleClick(ev, newSubject)}
                            size="large"
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1.1rem" />
                                ) : (
                                    <Add />
                                )
                            }
                        >
                            Create Course
                        </Button>
                    </Grid>
                </Grid>

                <FeeAlert
                    open={open}
                    setOpen={setOpen}
                    message={
                        <Typography variant="body1">
                            Are you sure you want to copy content from a
                            previous course? This action will duplicate all
                            materials and resources from the selected course
                            into the new one. Please note that any changes made
                            in the new course will not affect the original. This
                            action cannot be undone. Are you certain you
                            wish to proceed?
                        </Typography>
                    }
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(ev) => handleSubmit(ev)}
                        >
                            OK
                        </Button>
                    }
                />
            </AccordionDetails>
        </Accordion>
    );
}
