import { createTheme } from "@material-ui/core";

const theme = createTheme({
    palette: {
        type: "light",
        // text: {
        //     // primary: "#000",
        // },
        primary: {
            // main: "#fafafa",
            main: "#fcca4a",
        },
        // secondary: {
        //     // main: "#f4eee6",
        //     // main: "#f57c00",
        // },
        // background: {
        //     default: "#f4eee6",
        //     paper: "#f4eee6",
        // },
    },
});

export default theme;
