import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";
const endpoint = `${baseAPI}/class/subject`;
const endpoint2 = `${baseAPI}/class/assigned-subject`;
const url = `${baseAPI}/archive/subject/delete`;

export async function createSubject(newSubject) {
    const api = `${endpoint}/create`;
    try {
        const response = await axios.post(api, newSubject, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error);
        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export async function createoverAllCourse(newSubject) {
    const api = `${endpoint}/bulk`;
    try {
        const response = await axios.post(api, newSubject, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error);
        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export async function createSubjectCopy(newSubject) {
    const api = `${endpoint}/create`;
    try {
        console.log(newSubject);
        const response = await axios.post(api + "/copy/material", newSubject, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error);
        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export async function allSubjects(page = 1, limit = 10, filter = {}) {
    const api = `${endpoint}/all-subjects`;
    try {
        const response = await axios.get(api, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            params: { page, limit, ...filter },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error);

        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export async function allPublicSubjects(page = 1, limit = 20, filter = {}) {
    const api = `${endpoint}/all-public`;
    try {
        const response = await axios.get(api, {
            withCredentials: true,
            params: { page, limit, ...filter },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error);

        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export async function allSubjectsForFeed(
    classroomId,
    page = 1,
    limit = 10,
    filter = {}
) {
    const api = `${endpoint}/all-user-subjects`;
    try {
        const response = await axios.get(api, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            params: { classroom: classroomId, page, limit, ...filter },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error);

        return [[], error.response?.data?.message || notConnectedMessage];
    }
}

export async function allSubjectsCount() {
    return (
        await axios.get(`${endpoint}/all-subjects-count`, {
            withCredentials: true,
        })
    ).data.count;
}

export async function allAssignedSubjects() {
    const api = `${endpoint2}/all-assigned-subjects`;
    try {
        const response = await axios.get(api, { withCredentials: true });
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [[], err.response?.data?.message || notConnectedMessage];
    }
}

export async function allEmployeeSubjects(employee, page = 1, limit = 12) {
    const api = `${endpoint}/all-employee-subjects`;
    try {
        const response = await axios.get(api, {
            withCredentials: true,
            params: { employee, page, limit },
        });

        console.info(response.data);
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export async function allAssignedSubjectsCount() {
    return (
        await axios.get(`${endpoint2}/count`, {
            withCredentials: true,
        })
    )?.data?.count;
}

export async function subjectsByClassroom(classroom) {
    const api = `${endpoint}/all-user-subjects`;

    try {
        const response = await axios.get(api, {
            withCredentials: true,
            params: {
                classroom,
            },
            headers: { "Content-Type": "application/json" },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export async function subjectsByClassroomStudent(
    classroom,
    page = 1,
    limit = 10
) {
    const api = `${endpoint}/all-user-student-subjects`;

    try {
        const response = await axios.get(api, {
            withCredentials: true,
            params: {
                classroom,
                page,
                limit,
            },
            headers: { "Content-Type": "application/json" },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export const addPrivateSubject = async (token) => {
    try {
        const api = `${endpoint}/private/create`;
        const response = await axios.post(
            api,
            { token },
            { withCredentials: true }
        );
        return [response.data, null];
    } catch (err) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const editSubject = async (id, newSubject) => {
    try {
        const api = `${endpoint}/`;
        const response = await axios.patch(api, newSubject, {
            withCredentials: true,
            params: { id },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const createPublicSubjectEnrollment = async (subject) => {
    try {
        const api = `${endpoint}/private/create-for-public`;
        const response = await axios.post(
            api,
            {},
            {
                withCredentials: true,
                params: { subject },
            }
        );
        return [response.data, null];
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};
