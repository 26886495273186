import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

const endpoint = `${baseAPI}`;

export async function me() {
    const api = `${endpoint}/me`;

    try {
        const response = await axios.get(api, { withCredentials: true });
        return response.data;
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export async function isLoggedIn() {
    try {
        const response = await axios.get(`${endpoint}/user/isLogged`, {
            withCredentials: true,
        });
        return [response.data, null];
    } catch (error) {
        return [null, error.toJSON().message];
    }
}

export async function getUserPermissions() {
    const api = `${endpoint}/me/permissions`;
    const response = await axios.get(api, { withCredentials: true });
    return response.data;
}

export async function search(api, searchString, additionalParams = {}) {
    try {
        const response = await axios.get(`${endpoint}${api}`, {
            withCredentials: true,
            params: { searchString, ...additionalParams },
        });
        console.log(response.data);
        return [response.data, null];
    } catch (err) {
        console.log(err.response.data.message);
        return [[], err.response?.data?.message || notConnectedMessage];
    }
}

export async function getAllSettings() {
    return (
        await axios.get(`${endpoint}/me/settings`, { withCredentials: true })
    ).data;
}

export const getOrgPublicSettings = async (org) => {
    try {
        const response = await axios.get(`${endpoint}/me/org-public-settings`, {
            withCredentials: true,
            params: { org },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export async function changeName(name) {
    try {
        const api = `${endpoint}/class/change-name`;
        const response = await axios.patch(
            api,
            {},
            { withCredentials: true, params: { name } }
        );

        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export async function changeLogo(logo) {
    try {
        const api = `${endpoint}/class/change-logo`;
        const message = (
            await axios.patch(
                api,
                { logo },
                {
                    withCredentials: true,
                }
            )
        ).data.message;

        return [message, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export const loginUser = async (user) => {
    console.log("Logging in", user);
    try {
        const response = await axios.post(`${baseAPI}/baseuser/login`, user, {
            withCredentials: true,
        });
        console.log("response", response);
        return [response.data, null];
    } catch (err) {
        console.log("error login", err);
        return [null, err.response?.data || "Unable to login at this time"];
    }
};

export const createUser = async (user) => {
    try {
        const response = await axios.post(`${baseAPI}/baseuser/create`, user, {
            withCredentials: true,
        });
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export async function changeReportHeader(reportHeader) {
    try {
        const api = `${endpoint}/class/report-header`;
        const message = (
            await axios.patch(
                api,
                {},
                {
                    withCredentials: true,
                    params: { reportHeader },
                }
            )
        ).data.message;

        return [message, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}
