import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import UserContext from "./UserContext";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        color: "#fff",
        zIndex: theme.zIndex.drawer + 200,
    },
}));

const UniversalBackdropLoading = () => {
    const classes = useStyles();
    const { backdropLoading } = useContext(UserContext);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(0);
        const timer = setInterval(() => {
            setProgress((prevProgress) =>
                prevProgress >= 90 ? 95 : prevProgress + 10
            );
        }, 1200);

        return () => {
            clearInterval(timer);
        };
    }, [backdropLoading]);

    return (
        <Backdrop open={backdropLoading!} className={classes.backdrop}>
            <CircularProgress
                variant="determinate"
                color="primary"
                value={progress}
            />
        </Backdrop>
    );
};

export default UniversalBackdropLoading;
