import { ListItem, ListItemText } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const SimpleSidebarComponent = ({ href, name }: any) => {
    const history = useHistory();

    return (
        <ListItem
            button
            style={{ cursor: "pointer" }}
            onClick={() => {
                history.push(href);
            }}
        >
            <ListItemText primary={name} />
        </ListItem>
    );
};

export default SimpleSidebarComponent;
