import { useState, useContext } from "react";
import { ArchiveService } from "../../services/ArchiveService";
import { allSubjects, editSubject } from "../../services/subject.service";
import Authorize from "../Authorize";
import { AuthorizeFallback } from "../AuthorizeFallback";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import Filteration from "../Filteration/Filteration";
import { RUDTable } from "../Table/RUDTable";
import { AddSubject } from "../lms-user/AddSubject";
import AddSubjectEmployee from "./add-subject";
import { useTheme, Box } from "@material-ui/core";
import UserContext from "../UserContext";

export default function Subjects({ addSubject = false, variant }) {
    const theme = useTheme();
    const { user } = useContext(UserContext);
    const [subjectsAdded, setSubjectsAdded] = useState(0);

    const [filter, setFilter] = useState({
        search: "",
        apply: 0,
        classroom: "",
        owner: "",
        public: "",
    });
    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            apply: 0,
            search: "",
            classroom: "",
            owner: "",
            public: "",
        });
    };

    return (
        <>
            {addSubject && (
                <Authorize
                    opName="CREATE SUBJECT"
                    fallback={
                        <AuthorizeFallback message="You're not authorized to create a new Course" />
                    }
                >
                    {variant === "user" ? (
                        <AddSubject setSubjectsAdded={setSubjectsAdded} />
                    ) : (
                        <AddSubjectEmployee
                            setSubjectsAdded={setSubjectsAdded}
                        />
                    )}
                </Authorize>
            )}

            <RUDTable
                getter={(page, limit) =>
                    allSubjects(page, limit, {
                        search: filter.search ? filter.search : null,
                        classroom: filter.classroom ? filter.classroom : null,
                        owner: filter.owner ? filter.owner : null,
                        public:
                            filter.public === 1
                                ? 1
                                : filter.public === 0
                                ? 0
                                : null,
                    })
                }
                editor={editSubject}
                readables={{
                    id: "ID",
                    subject: "Name",
                    owner: "Owner",
                    classroomName: "Classroom",
                    public: "Visibility",
                }}
                rowsPreprocessor={(r) => ({
                    ...r,
                    owner: r.owner.name,
                    deletionId: r.id,
                    classroomName: r.classroom.class,
                    public: `${r.public ? "Public" : "Private"}`,
                    defaultVisibility: r.public
                        ? { id: true, name: "Public" }
                        : { id: false, name: "Private" },
                    deafultOwner: r.owner,
                    defaultClassroom: r.classroom,
                })}
                deletor={(ids) => ArchiveService.archive(ids[0], "subject")}
                ops={{
                    read: "READ SUBJECTS",
                    edit: "EDIT SUBJECT",
                    delete: "DELETE SUBJECTS",
                }}
                editables={[
                    {
                        name: "subject",
                        type: "textfield",
                        label: "New Subject name",
                        isRequired: true,
                    },

                    {
                        name: "public",
                        type: "autocomplete",
                        label: "Visibility",
                        isRequired: true,
                        autocomplete: {
                            labelKey: "name",
                            label: "Visibility",
                            defaultOptions: [
                                { id: false, name: "Private" },
                                { id: true, name: "Public" },
                            ],
                            defaultValue: "defaultVisibility",
                        },
                    },

                    {
                        name: "owner",
                        type: "autocomplete",
                        isRequired: true,
                        label: "Owner",
                        autocomplete: {
                            labelKey: "name",
                            label: "Owner",
                            api: "/employee/all-employees",
                            defaultValue: "deafultOwner",
                            // apiParams: { classroom: null },
                        },
                    },

                    // {
                    //     name: "classroom",
                    //     type: "autocomplete",
                    //     label: "Classroom",
                    //     autocomplete: {
                    //         label: "Classroom",
                    //         labelKey: "class",
                    //         api: "/class/all-classes-admin",
                    //         defaultValue: "defaultClassroom",
                    //         // apiParams: { classroom: null },
                    //     },
                    // },
                ]}
                opsMessages={{
                    read: "You're not authorized",
                }}
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                        fields={[
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                                style={{ gap: theme.spacing(2) }}
                            >
                                {user && !user.classroom && (
                                    <Autocomplete
                                        labelKey="class"
                                        label="Classroom"
                                        api="/class/all-classes-admin"
                                        setOutput={(r) =>
                                            setFilter({
                                                ...filter,
                                                classroom: r?.id || null,
                                            })
                                        }
                                        clear={clearFilters}
                                        textFieldProps={{ variant: "outlined" }}
                                    />
                                )}

                                {user && user.baseUser.type != "employee" && (
                                    <Autocomplete
                                        labelKey="name"
                                        label="Employee"
                                        api="/employee/all-employees"
                                        setOutput={(r) =>
                                            setFilter({
                                                ...filter,
                                                owner: r?.id || null,
                                            })
                                        }
                                        clear={clearFilters}
                                        textFieldProps={{ variant: "outlined" }}
                                        apiParams={{
                                            classroom: filter.classroom
                                                ? filter.classroom
                                                : undefined,
                                        }}
                                    />
                                )}

                                <Autocomplete
                                    labelKey="name"
                                    label="Visibility"
                                    defaultOptions={[
                                        { id: 0, name: "Private" },
                                        { id: 1, name: "Public" },
                                    ]}
                                    setOutput={(r) =>
                                        setFilter({
                                            ...filter,
                                            public: r?.id,
                                        })
                                    }
                                    clear={clearFilters}
                                    textFieldProps={{ variant: "outlined" }}
                                />
                            </Box>,
                        ]}
                    />
                }
                updatingAgents={[
                    subjectsAdded,
                    filter.search,
                    filter.apply,
                    // filter.public,
                ]}
            />
        </>
    );
}
