import { useTheme } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useContext } from "react";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";
import UserContext from "./components/UserContext";
import Sidebar, {
    facultyModules,
    studentModules,
    userModules,
} from "./components/dashboard/Sidebar";

const AsyncHomepage = Loadable({
    loader: () => import("./components/Login/Login"),

    loading: Loading,
});

const AsyncProctorExam = Loadable({
    loader: () => import("./components/ProctorExam/ProctorExamLobby"),

    loading: Loading,
});

const AsyncSignIn = Loadable({
    loader: () => import("./components/Login/Login"),
    loading: Loading,
});

const AsyncOfflinePage = Loadable({
    loader: () => import("./components/Auth/OfflinePage"),
    loading: Loading,
});

const AsyncSignUp = Loadable({
    loader: () => import("./components/Signup/Signup"),
    loading: Loading,
});

const AsyncChat = Loadable({
    loader: () => import("./components/Chat/Chat"),
    loading: Loading,
});

const AsyncDesignLecture = Loadable({
    loader: () => import("./components/Lecture/Create/DesignLecture"),
    loading: Loading,
});

const AsyncSelectClassroomSubject = Loadable({
    loader: () => import("./components/classwork/SelectClassworkSubject"),
    loading: Loading,
});

const AsyncDetailInfo = Loadable({
    loader: () => import("./components/DetailedInfo"),
    loading: Loading,
});

const AsyncEmployeeStudent = Loadable({
    loader: () => import("./components/lms/EmployeeStudent"),
    loading: Loading,
});

const AsyncCertificateHistory = Loadable({
    loader: () =>
        import("./components/Certificates/History/CertificateHistory"),
    loading: Loading,
});

const AsyncClasswork = Loadable({
    loader: () => import("./components/classwork/Classwork"),
    loading: Loading,
});

const AsyncUpdateMaterial = Loadable({
    loader: () => import("./components/classwork/Material/UpdateMaterial"),
    loading: Loading,
});

const AsyncShowAssignment = Loadable({
    loader: () => import("./components/classwork/Assignment/ShowAssignment"),
    loading: Loading,
});

const AsyncUpdateAssignment = Loadable({
    loader: () => import("./components/classwork/Assignment/UpdateAssignment"),
    loading: Loading,
});

const AsyncUserPrefrences = Loadable({
    loader: () => import("./components/settings/UserPreferences"),
    loading: Loading,
});

const AsyncSimpleDashboard = Loadable({
    loader: () => import("./components/SimpleDashboard/SimpleDashboard"),
    loading: Loading,
});

const AsyncSession = Loadable({
    loader: () => import("./components/LiveSession/Sessions"),
    loading: Loading,
});

const AsyncSinglePayment = Loadable({
    loader: () =>
        import("./components/Payment/Payments/SinglePayment/SinglePayment"),
    loading: Loading,
});

const AsyncCreateForm = Loadable({
    loader: () => import("./components/Form/CreateForm"),
    loading: Loading,
});

const AsyncViewForm = Loadable({
    loader: () => import("./components/Form/ViewForm"),
    loading: Loading,
});

const AsyncSubmitForm = Loadable({
    loader: () => import("./components/Form/SubmitForm"),
    loading: Loading,
});

const AsyncFormSubmitSuccess = Loadable({
    loader: () => import("./components/Form/FormSubmitSuccess"),
    loading: Loading,
});

const AsyncEvent = Loadable({
    loader: () => import("./components/Events/Event"),
    loading: Loading,
});

const AsyncViewEvent = Loadable({
    loader: () => import("./components/Events/ViewEvent"),
    loading: Loading,
});

const AsyncCreateProcterExam = Loadable({
    loader: () => import("./components/ProctorExam/CreateProctorExam"),
    loading: Loading,
});

const AsyncThankuPage = Loadable({
    loader: () => import("./components/ThankUPage"),
    loading: Loading,
});

const AsyncTakeProcterExam = Loadable({
    loader: () => import("./components/Exam/TakeProctorExam"),
    loading: Loading,
});

const AsyncCreateCertificate = Loadable({
    loader: () => import("./components/Certificates/CreateCertificate"),
    loading: Loading,
});

const AsyncViewExam = Loadable({
    loader: () => import("./components/Exam/ViewExam"),
    loading: Loading,
});

const AsyncErrorBoundry = Loadable({
    loader: () => import("./components/ErrorBoundary"),
    loading: Loading,
});

const AsyncAuthorizeAsType = Loadable({
    loader: () => import("./components/Auth/AuthorizeAsTypes"),
    loading: Loading,
});

const AsyncAuthenticate = Loadable({
    loader: () => import("./components/Authenticate"),
    loading: Loading,
});

const AsyncFourOfFour = Loadable({
    loader: () => import("./components/FourOfFour"),
    loading: Loading,
});

const AsyncAuthorizeLectureEdit = Loadable({
    loader: () => import("./components/Lecture/Create/AuthorizeLectureEdit"),
    loading: Loading,
});

const AsyncLiveSession = Loadable({
    loader: () => import("./components/LiveSession/Session"),
    loading: Loading,
});

const AsyncMultiPermissionAuthorize = Loadable({
    loader: () => import("./components/MultiPermissionAuthorize"),
    loading: Loading,
});

const AsyncCreateRole = Loadable({
    loader: () => import("./components/Rolesystem/CreateRole"),
    loading: Loading,
});

const AsyncViewGroupItem = Loadable({
    loader: () => import("./components/classwork/CourseContents/ViewGroupItem"),
    loading: Loading,
});

const AsyncShowQuiz = Loadable({
    loader: () => import("./components/classwork/Quiz/ShowQuiz"),
    loading: Loading,
});

const AsyncSelectClassroom = Loadable({
    loader: () => import("./components/classwork/SelectClassroom"),
    loading: Loading,
});

const AsyncLayout = Loadable({
    loader: () => import("./components/layout"),
    loading: Loading,
});

const Fallback = () => {
    return (
        <Alert style={{ margin: "8px" }} severity="error">
            <AlertTitle>Access Denied</AlertTitle>
            You do not have the clearance to access this page
        </Alert>
    );
};

export const Routes = () => {
    const { user } = useContext(UserContext);
    const theme = useTheme();
    return (
        <AsyncLayout
            sidebar={
                user ? (
                    <Sidebar
                        variant={user.baseUser.type}
                        setComponent={() => {}}
                    />
                ) : null
            }
        >
            <Switch>
                <Route exact path="/offline">
                    <AsyncOfflinePage />
                </Route>

                <Route exact path="/login">
                    <AsyncErrorBoundry>
                        <AsyncSignIn />
                    </AsyncErrorBoundry>
                </Route>

                <Route exact path="/login/:org">
                    <AsyncErrorBoundry>
                        <AsyncSignIn />
                    </AsyncErrorBoundry>
                </Route>

                <Route exact path="/signup">
                    <AsyncErrorBoundry>
                        <AsyncSignUp />
                    </AsyncErrorBoundry>
                </Route>

                <Route exact path="/chat">
                    <AsyncAuthenticate>
                        <AsyncChat />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/lecture/design">
                    <AsyncAuthenticate>
                        <AsyncAuthorizeLectureEdit>
                            <AsyncDesignLecture />
                        </AsyncAuthorizeLectureEdit>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/employee">
                    <AsyncAuthenticate>
                        <AsyncSelectClassroomSubject />
                        {/* <SelectClassroom
                            variant="employee"
                            userId={user?.id}
                            setComponent={() => {}}
                        /> */}
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/student">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["READ CLASSWORK"]}>
                            <AsyncSelectClassroomSubject />
                            {/* <SelectClassroom
                                variant="student"
                                userId={user?.id}
                                setComponent={() => {}}
                            /> */}
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/detail-info/:id">
                    <AsyncAuthenticate>
                        {/* <MultiPermissionAuthorize ops={["READ CLASSWORK"]}> */}
                        <AsyncDetailInfo />
                        {/* <SelectClassroom
                                variant="student"
                                userId={user?.id}
                                setComponent={() => {}}
                            /> */}
                        {/* </MultiPermissionAuthorize> */}
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/employee/student">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["READ STUDENTS"]}>
                            <AsyncEmployeeStudent />
                            {/* <SelectClassroom
                                variant="student"
                                userId={user?.id}
                                setComponent={() => {}}
                            /> */}
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/student/assigned/certificate">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["READ CERTIFICATE_HISTORY"]}
                        >
                            <AsyncCertificateHistory />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                {user && (
                    <Route exact path="/classwork/classrooms">
                        <AsyncAuthenticate>
                            <AsyncSelectClassroom
                                userId={user.id}
                                variant={user.baseUser.type}
                                setComponent={() => {}}
                            />
                        </AsyncAuthenticate>
                    </Route>
                )}

                <Route exact path="/classwork/classrooms/courses">
                    <AsyncAuthenticate>
                        <AsyncSelectClassroomSubject />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork">
                    <AsyncAuthenticate>
                        <AsyncClasswork />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork/material">
                    <AsyncAuthenticate>
                        <AsyncViewGroupItem
                            open={true}
                            variant="material"
                            contentId={null}
                        />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork/assignment">
                    <AsyncAuthenticate>
                        <AsyncViewGroupItem
                            open={true}
                            variant="assignment"
                            contentId={null}
                        />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork/material/update">
                    <AsyncAuthenticate>
                        {/* <MultiPermissionAuthorize ops={["EDIT CLASSWORK"]}> */}
                        <AsyncUpdateMaterial />
                        {/* </MultiPermissionAuthorize> */}
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork/assignment">
                    <AsyncAuthenticate>
                        <AsyncShowAssignment />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork/assignment/update">
                    <AsyncAuthenticate>
                        {/* <MultiPermissionAuthorize ops={["EDIT CLASSWORK"]}> */}
                        <AsyncUpdateAssignment />
                        {/* </MultiPermissionAuthorize> */}
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork/quiz">
                    <AsyncAuthenticate>
                        <AsyncShowQuiz open quizId={null} />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/classwork/quiz/submissions">
                    <AsyncAuthenticate>
                        <AsyncShowQuiz activeTab="submissions" />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/settings">
                    <AsyncAuthenticate>
                        <AsyncUserPrefrences />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/simple-dashboard">
                    {/* <Authenticate> */}
                    <AsyncSimpleDashboard />
                    {/* </Authenticate> */}
                </Route>

                <Route exact path="/role/update/:id">
                    <AsyncAuthenticate>
                        <AsyncCreateRole mode="edit" />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/role/read/:id">
                    <AsyncAuthenticate>
                        <AsyncCreateRole mode="read" />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/live/sessions">
                    <AsyncAuthenticate>
                        <AsyncSession />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/live/coaching-session/owner/:sessionId">
                    <AsyncAuthenticate>
                        <AsyncLiveSession isMaster={true} />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/live/coaching-session/viewer/:sessionId">
                    <AsyncAuthenticate>
                        <AsyncLiveSession isMaster={false} />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/user/certificate/create">
                    <AsyncAuthenticate>
                        <AsyncCreateCertificate mode="create" />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/user/certificate/update/:id">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["EDIT CERTIFICATE"]}
                        >
                            <AsyncCreateCertificate mode="update" />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/:userType/payment/details/:id">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["READ PAYMENT", "READ COLLECTION"]}
                        >
                            <AsyncSinglePayment />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/:userType/payment/collection/details/:id">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["READ PAYMENT", "READ COLLECTION"]}
                        >
                            <AsyncSinglePayment />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                {/* USER */}

                <Route exact path="/user/form/create">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["CREATE FORM"]}>
                            <AsyncCreateForm />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/user/form/view/:id">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["READ FORM"]}>
                            <AsyncViewForm />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/form/submit/:id">
                    <AsyncSubmitForm />
                </Route>

                <Route exact path="/form/receipt/:id">
                    <AsyncFormSubmitSuccess />
                </Route>

                {/*  */}

                <Route exact path="/user/event/create">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["CREATE EVENT"]}>
                            <AsyncEvent />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/user/event/view/:id">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["READ EVENT"]}>
                            <AsyncViewEvent />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/user/proctor-exam/create">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["CREATE EXAM"]}>
                            <AsyncCreateProcterExam />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                {/*  */}

                <Route exact path="/event/submitted">
                    <AsyncThankuPage />
                </Route>

                {/*  */}

                {/* <Route exact path="/employee/:examType/create/">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["CREATE EXAM"]}>
                            <AsyncCreateProcterExam />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route> */}

                <Route exact path="/employee/exam/view/:id">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["READ EXAM"]}>
                            <AsyncViewExam />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route
                    exact
                    path="/:userType/proctor-exams/:proctorExamId/lobby"
                >
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["READ EXAM"]}>
                            <AsyncProctorExam />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/:userType/:examType/create/">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["CREATE EXAM"]}>
                            <AsyncCreateProcterExam />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                {/* Proctor Exam */}

                <Route exact path="/student/exam/take/:proctorExamId">
                    <AsyncAuthenticate>
                        <AsyncTakeProcterExam />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/student/exam-submitted">
                    <AsyncAuthenticate>
                        <AsyncThankuPage />
                    </AsyncAuthenticate>
                </Route>

                {/*  */}

                {Object.values(userModules)
                    .flat()
                    .map((subModule, idx) => (
                        <Route exact path={`${subModule.href}`} key={idx}>
                            <AsyncAuthenticate>
                                <AsyncAuthorizeAsType types={["user"]}>
                                    <AsyncMultiPermissionAuthorize
                                        strategy="some"
                                        ops={subModule.permissions || []}
                                        fallback={<Fallback />}
                                    >
                                        {subModule.component}
                                    </AsyncMultiPermissionAuthorize>
                                </AsyncAuthorizeAsType>
                            </AsyncAuthenticate>
                        </Route>
                    ))}

                {/* FACULTY */}
                {Object.values(facultyModules)
                    .flat()
                    .map((subModule, idx) => (
                        <Route exact path={`${subModule.href}`} key={idx}>
                            <AsyncAuthenticate>
                                <AsyncAuthorizeAsType types={["employee"]}>
                                    <AsyncMultiPermissionAuthorize
                                        strategy="all"
                                        ops={subModule.permissions || []}
                                        fallback={<Fallback />}
                                    >
                                        {subModule.component}
                                    </AsyncMultiPermissionAuthorize>
                                </AsyncAuthorizeAsType>
                            </AsyncAuthenticate>
                        </Route>
                    ))}

                {Object.values(studentModules)
                    .flat()
                    .map((subModule, idx) => (
                        <Route exact path={`${subModule.href}`} key={idx}>
                            <AsyncAuthenticate>
                                <AsyncAuthorizeAsType types={["student"]}>
                                    <AsyncMultiPermissionAuthorize
                                        strategy="all"
                                        ops={subModule.permissions || []}
                                        fallback={<Fallback />}
                                    >
                                        {subModule.component}
                                    </AsyncMultiPermissionAuthorize>
                                </AsyncAuthorizeAsType>
                            </AsyncAuthenticate>
                        </Route>
                    ))}

                <Route exact path="/">
                    <AsyncAuthenticate>
                        <AsyncHomepage
                            logo="icon.png"
                            schoolName="Zama School"
                        />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="*">
                    <AsyncFourOfFour />
                </Route>
            </Switch>
        </AsyncLayout>
    );
};
