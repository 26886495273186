import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class ArchiveService {
    static url = `${baseAPI}/archive`;

    static async getClassrooms({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(
                ArchiveService.url + "/classroom",
                {
                    withCredentials: true,
                    params: {
                        page,
                        limit,
                        filter,
                    },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getUsers({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/user", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudents({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/student", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getEmployees({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/employee", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreClassroom(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/classroom/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreUser(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/user/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreEmployee(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/employee/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreStudent(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/student/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClasswrok({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(
                ArchiveService.url + "/classwork",
                {
                    withCredentials: true,
                    params: {
                        page,
                        limit,
                        filter,
                    },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async retoreClasswork(
        id: number | string,
        type: "material" | "assignment" | "quiz"
    ) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/classwork/restore",
                {},
                {
                    withCredentials: true,
                    params: { id, type },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async archive(id: string | number, subEndpoint: string) {
        try {
            const response = await axios.post(
                ArchiveService.url + `/${subEndpoint}`,
                {},
                { withCredentials: true, params: { id } }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async archiveClasswork(
        id: string | number,
        type: "material" | "assignment" | "quiz" | "lecture"
    ) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/classwork",
                {},
                { withCredentials: true, params: { id, type } }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getRoles({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/role", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreRole(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/role/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSubject({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/subject", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreSubject(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/subject/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSubjects(id: any) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/subject/delete",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getChapter({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/chapter", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreChapter(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/chapter/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteChapter(id: any) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/chapter/delete",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTopic({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/topic", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreTopic(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/topic/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteTopic(id: any) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/topic/delete",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getPayments({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(ArchiveService.url + "/payment", {
                withCredentials: true,
                params: {
                    page,
                    limit,
                    filter,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restorePayment(id: any, type: any) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/payment/restore",
                {},
                {
                    withCredentials: true,
                    params: { id, type },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deletePayment(id: any, type: any) {
        try {
            console.log(type);
            const response = await axios.post(
                ArchiveService.url + "/payment/delete",
                {},
                {
                    withCredentials: true,
                    params: { id, type },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getPaymentsAssociation({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(
                ArchiveService.url + "/payment/association",
                {
                    withCredentials: true,
                    params: {
                        page,
                        limit,
                        filter,
                    },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restorePaymentAssociation(id: any, type: any) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/payment/association/restore",
                {},
                {
                    withCredentials: true,
                    params: { id, type },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deletePaymentPaymentAssociation(id: any, type: any) {
        try {
            console.log(type);
            const response = await axios.post(
                ArchiveService.url + "/payment/association/delete",
                {},
                {
                    withCredentials: true,
                    params: { id, type },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getCertificate({ page = 1, limit = 10, filter = {} }) {
        try {
            const response = await axios.get(
                ArchiveService.url + "/certificate",
                {
                    withCredentials: true,
                    params: {
                        page,
                        limit,
                        filter,
                    },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async restoreCertificate(id: number | string) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/certificate/restore",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteCertificate(id: any) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/certificate/delete",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
