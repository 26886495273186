import { createTheme } from "@material-ui/core";

export default createTheme({
    palette: {
        type: "dark",
        primary: {
            // main: "#16181c",
            main: "#fcca4a",
            // contrastText: "#ffffff",
        },
        secondary: {
            // main: "rgb(51,51,51)",
            // contrastText: "#212121",
            main: "#2196f3",
        },
        background: {
            // default: "rgb(35,35,35)",
            // paper: "rgb(35,35,35)",
        },
        // background: {
        //     default: "#616161",
        //     paper: "#e0e0e0",
        // },
        // text: {
        //     primary: "#ffffff",
        //     secondary: "#212121",
        // },
        // action: {
        //     selected: "#616161",
        // },
    },
    typography: {
        fontFamily: `'Google Sans',Roboto,Arial,'Sans serif'`,
    },
});
