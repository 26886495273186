import {
    Collapse,
    IconButton,
    TextField,
    TextFieldProps,
    Typography,
    TypographyProps,
    useTheme,
} from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import { Done } from "@material-ui/icons";
import { useEffect, useState } from "react";

type Mode = "read" | "write";
const initialMode: Mode = "read";

type EditableTypographyProps = {
    textFieldProps?: TextFieldProps;
    typoProps?: TypographyProps;
    initialValue: string;
    onEdit: (value: string) => void;
};

export function EditableTypography({
    textFieldProps = {},
    typoProps = {},
    initialValue,
    onEdit,
}: EditableTypographyProps) {
    const [mode, setMode] = useState(initialMode);
    const [value, setValue] = useState(initialValue);

    //
    const theme = useTheme();

    //
    function handleChange() {
        setMode("read");
        onEdit(value);
    }

    //
    useEffect(() => {}, []);

    //
    return (
        <div>
            <Collapse in={mode === "read"}>
                <Typography {...typoProps} onClick={() => setMode("write")}>
                    {value}
                </Typography>
            </Collapse>

            <Collapse in={mode === "write"}>
                <TextField
                    {...textFieldProps}
                    value={value}
                    onChange={(event) =>
                        setValue(
                            event.target.value || initialValue || "Untitled"
                        )
                    }
                    inputProps={{
                        style: typoProps.variant
                            ? {
                                  fontSize:
                                      theme.typography[
                                          typoProps.variant as Variant
                                      ].fontSize,
                              }
                            : undefined,
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconButton size="small" onClick={handleChange}>
                                <Done fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Collapse>
        </div>
    );
}
