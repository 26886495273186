import { Icon, Typography } from "@material-ui/core";
import { Component, ErrorInfo, ReactNode } from "react";
import ErrorIcon from "@mui/icons-material/Error";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        width: "60%",
                        height: "calc(100vh - 64px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                    }}
                >
                    <div style={{ textAlign: "center" }}>
                        <Icon>
                            {/* <Public style={{ fontSize: "7rem" }} /> */}
                            <ErrorIcon
                                style={{
                                    color: "red",
                                    backgroundColor: "white",
                                    fontSize: "5rem",
                                    borderRadius: "50%",
                                }}
                            />
                        </Icon>
                        <Typography variant="h6">
                            Uh-oh! It seems we've encountered a hiccup. Please
                            bear with us as we work to resolve the issue and get
                            back to smooth sailing!
                        </Typography>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
