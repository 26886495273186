import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useContext } from "react";
import UserContext from "./UserContext";

const UniversalFloatingFeedback = () => {
    const { feedback, setShow } = useContext(UserContext);
    return (
        <Snackbar
            open={feedback!.show}
            onClose={() => setShow!(false)}
            message={feedback?.message}
            title={feedback?.title}
            autoHideDuration={6 * 1000}
        >
            <Alert severity={feedback?.severity}>{feedback?.message}</Alert>
        </Snackbar>
    );
};

export default UniversalFloatingFeedback;
